



























































import { Component, Vue, Watch } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import { ChannelService, GaodeService } from "src/services";
import pageBack from "src/components/page-back";
import dayjs from "dayjs";
import { DoctorTitle_TYPE } from "src/enums";
import EnumUtils from "src/utils/enum-utils";
declare let WeixinJSBridge: any 
declare let document: any

@Component({
    components:
    {
        pageBack
    }
})
export default class Channel extends Vue
{   
    /**
     * 当前选中用户
     * @protected
     * @returns string
     */
    protected selectUser: any = {};

    /**
     * 选择人员
     * @protected
     * @returns boolean
     */
    protected showUserPopup: boolean = false;

    /**
     * 医生数据
     * @private
     * @returns void
     */
    private doctorJson: any =
    {
        avatar: "",
        departmentId: null,
        departmentName: "",
        doctorName: "",
        doctorTitle: null,
        hospType: null,
        hospitalId: null,
        hospitalName: "",
        image: null,
        introduction: "",
        isEnableGreenway: false,
        sex: null,
        specialty: "",
        type: null,
        typeName: ""
    }
    
    /**
     * 绿通项目数据
     * @private
     * @returns void
     */
    private greenwayItem: any =
    {
        serverItemName: "",
        serverPrice: null
    }


    /**
     * form表单数据
     * @private
     * @returns void
     */
    private formData : any = 
    {
        reserveTime: "",
        remark: "",
        doctorId: null,
        memberServiceItemId: null
    }

    // 当前日期后3天
    private dateThree: any = new Date(dayjs().add(3, 'day').valueOf());

    /**
     * 跳转详情
     * @private
     * @returns void
     */
    private onMake(): void
    {
        if (!this.formData.reserveTime)
        {
            Toast("请先选择预约时间！");
            return;
        }
        let params : any = {
            memberId: this.selectUser.id,
            reserveTime: this.formData.reserveTime,
            serverItemId: this.formData.serverItemId,
            doctorId: this.formData.doctorId,
            remark: this.formData.remark,
            hospitalId: this.doctorJson.hospitalId,
            departmentId: this.doctorJson.departmentId
        }
        if (this.formData.memberServiceItemId)
        {
            params.memberServiceItemId = this.formData.memberServiceItemId;
        }
        Dialog.confirm({
            title: '提示',
            message: '确定要预约吗？',
        })
        .then(async () => {
            try
            {
                let {content: res} = await ChannelService.instance.addGreenwayOrder(params);
                Toast.success("预约成功！");
                let orderId = null;
                if (res.data)
                {
                    orderId = res.data.orderId
                }
                // 需要支付
                if (res.data.orderStatus == 1)
                {
                    this.onBridgeReady(res.data, res.data.orderId);
                }
                else // 不需要支付
                {
                    this.$router.push({name: "cellStockpile-makeSuccss", query:{orderId: orderId, actionName: "channel-order-detail"}});
                }
                
            }
            catch (err)
            {
                Toast(err)
            }
        })
        .catch(() => {
            // on cancel
        });
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    protected get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 生命钩子函数
     * @private
     * @returns void
     */
    private created():void
    {
        this.formData.reserveTime = this.$route.query.makeDate || "";
        this.formData.doctorId = parseInt((this.$route.query as any).doctorId);
        this.formData.serverItemId = parseInt((this.$route.query as any).serverItemId);
        this.formData.memberServiceItemId = parseInt((this.$route.query as any).memberServiceItemId);
        
        this.getDoctor();
        this.getGreenwayItem();
        this.selectUser =
        {
            id: this.userInfo.userId,
            memberType: 1,
            familyType: 1,
            memberName: this.userInfo.userName
        };
    }
    /**
     * 获取绿通项目
     * @private
     * @returns Promise<void>
     */
    private async getGreenwayItem(): Promise<void>
    {
        try
        {
            let {content: result} = await ChannelService.instance.getGreenwayItem({doctorId: this.formData.doctorId, serviceItemId: this.formData.serverItemId});
            if (result.data)
            {
                this.greenwayItem = Object.assign({}, result.data);
            }
            else
            {
               Toast("绿通项目数据有误！"); 
            }
        }
        catch (err)
        {
            Toast(err);
        }
    }
    /**
     * 获取医生信息
     * @private
     * @returns Promise<void>
     */
    private async getDoctor(): Promise<void>
    {
        try
        {
            let {content: result} = await ChannelService.instance.getDoctor(this.formData.doctorId);
            if (result.data)
            {
                this.doctorJson = Object.assign({}, result.data);
            }
            else
            {
               Toast("医生数据有误！"); 
            }
        }
        catch (err)
        {
            Toast(err);
        }
    }
    /**
     * 医生职级类型
     * @protected
     */
    protected onDoctorTitleType(value): string
    {
        return EnumUtils.getEntry(value, DoctorTitle_TYPE)?.description || "";
    }

    /**
     * 确认切换用户
     * @private
     * @returns void
     */
    protected onConfirmUser(data: any): void
    {
        if(data.id !== this.selectUser.id)
        {
            this.selectUser = Object.assign({},data);
        }
    }
    
    /**
     * 付款
     * @private
     * @returns void
     */
    private onBridgeReady(model: any, orderId: string): void
    {
        let data =  model.payRes && model.payRes.parameter && JSON.parse(model.payRes.parameter)
        let self = this;
        WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            {
                appId: data.appId, // 公众号名称，由商户传入
                timeStamp: data.timeStamp, // 时间戳，自1970年以来的秒数
                nonceStr: data.nonceStr, // 随机串
                package: data.package, //
                signType: data.signType, // 微信签名方式:
                paySign: data.paySign // 微信签名
            },
            function(res)
            {
                if (res.err_msg === "get_brand_wcpay_request:ok")
                {
                    self.$router.push({name: "cellStockpile-makeSuccss", query:{orderId: orderId, actionName: "channel-order-detail"}});
                    Toast.success("支付成功");
                    // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                }
                else if(res.err_msg === "get_brand_wcpay_request:cancel")
                {
                    // 取消支付
                    self.$router.push({name: "channel-order-detail", query:{orderId: orderId}});
                }
                else
                {
                    self.$router.push({name: "channel-order-detail", query:{orderId: orderId}});
                    Toast.fail("支付失败");
                }
            }
        );
    }
    
}
