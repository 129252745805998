
















































































import { Component, Vue } from "vue-property-decorator";
import { Toast } from "vant";
import { DoctorTitle_TYPE } from "src/enums";
import EnumUtils from "src/utils/enum-utils";
import { ChannelService } from "src/services";
import dayjs from "dayjs";

@Component
export default class OrderList extends Vue
{


    /**
     * tab选中
     * @private
     * @returns number | string
     */
    private active: number | string = "";

    /**
     * 时间处理工具
     * @private
     * @returns dayjs
     */
    private dayjs: any = dayjs;

    /**
     * 订单列表
     * @private
     * @returns Array<any>
     */
    private orderList: Array<any> = [];

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 获取订单列表 
     */
    protected async loadData(parameter: any): Promise<any> {
        try {
            let { content: res } = await ChannelService.instance.h5QueryGreenwayOrder(
                Object.assign({orderStatus: this.active}, parameter)
            );
            return res.data;
        } catch (err) {
            Toast(err);
        }
    }

    /**
     * tab切换
     * @private
     * @returns void
     */
    private onTabChange(item: number): void
    {
        (this.$refs.myUPageList as any).onRefresh();
    }

    /**
     * 跳转详情
     * @private
     * @returns void
     */
    private onDetail(id: number): void
    {
        this.$router.push({name: "channel-order-detail", query: {orderId: id.toString()}});
    }

    /**
     * 医生职级类型
     * @protected
     */
    protected onDoctorTitleType(value): string
    {
        return EnumUtils.getEntry(value, DoctorTitle_TYPE)?.description || "";
    }

}
