







































































































































import { Component, Vue } from "vue-property-decorator";
import {  FileService } from "src/services";
import { Toast, Dialog } from "vant";
import { tools } from "src/utils";
import EnumUtils from "src/utils/enum-utils";
import { RELATION } from "src/enums";
import dayjs from "dayjs";
declare let WeixinJSBridge: any 
declare let document: any

Component.registerHooks([
    "beforeRouteEnter",
    "beforeRouteLeave",
    "beforeRouteUpdate" // for vue-router 2.2+
]);

@Component
export default class MakeHospital extends Vue
{
    
    /**
     * pop备注框值
     * @protected
     * @returns string
     */
    protected popRemarkValue: string = "";

    /**
     *  是否显示备注框弹出层
     * @protected
     * @returns boolean
     */
    protected showRemark: boolean = false;

    /**
     * 预约最小日期
     * @protected
     * @returns Date
     */
    protected minDate: Date= dayjs().add(3, "day").toDate();

    /**
     * 确诊最小日期
     * @protected
     * @returns Date
     */
    protected minDiagnosisTime: Date= new Date(1970, 1, 1);

    /**
     * 确诊最小日期
     * @protected
     * @returns Date
     */
    protected maxDiagnosisTime: Date= dayjs().toDate();

    /**
     * 是否患有重大疾病弹出框
     * @protected
     * @returns boolean
     */
    protected showDisease: boolean = false;

    /**
     * 是否持有该医院的住院通知单
     * @protected
     * @returns boolean
     */
    protected showNotify: boolean = false;

    /**
     * 是否患有重大疾病数组
     * @protected
     * @returns Array<any>
     */
    protected columnsDisease: Array<any> = ['是', '否'];

    /**
     * 服务项目类型 1:绿通项目 2:细胞存储 3:心理咨询
     * @private
     * @returns number
     */
    private itemType: number;

    /**
     * 当前选中用户
     * @protected
     * @returns string
     */
    protected selectUser: any = {};

    /**
     * 选择人员
     * @protected
     * @returns boolean
     */
    protected showUserPopup: boolean = false;
    
    /**
     * form表单数据
     * @protected
     * @returns string
     */
    protected formModel: any = {
        memberId: null,
        reserveTime: "",
        hospitalServerId: null,
        hospitalId: null,
        hospitalName: "",
        department: "",
        linkPhone: "",
        isDisease: '否',
        diagnosisTime: "",
        diagnosisHospital: "",
        diagnosisDepartment: "",
        diagnosisResult: "",
        isHospitalNotice: '否',
        diagnosisPicture: ""
    };

    /**
     * pop诊断结果
     * @protected
     * @returns string
     */
    protected popFruitValue: string = "";


    /**
     *  是否显示诊断结果弹出层
     * @protected
     * @returns boolean
     */
    protected showFruit: boolean = false;

    /**
     *  显示日期
     * @protected
     * @returns boolean
     */
    protected showDate: boolean = false;

    /**
     *  确诊时间
     * @protected
     * @returns boolean
     */
    protected showDiagnosisTime: boolean = false;
    
    /**
     * 医生数据
     * @private
     * @returns void
     */
    private doctorJson: any =
    {
        address: "",
        areaId: null,
        areaName: "",
        avatar: "",
        avatarPath: {fullPath:""},
        cityId: null,
        cityName: "",
        departmentId: null,
        departmentName: "",
        departmentType: null,
        departmentTypeStr: "",
        doctorName: "",
        doctorTitle: null,
        doctorTitleStr: "",
        fullAddress: "",
        hospType: null,
        hospTypeStr: "",
        hospitalId: null,
        hospitalName: "",
        introduction: "",
        provinceId: null,
        provinceName: "",
        serverItemName: null,
        serverPrice: null,
        sex: null,
        sexStr: "",
        specialty: "",
        typeName: null
    }

    /**
     * 权益id
     * @private
     * @returns string
     */
    private get rightsId(): string
    {
        return this.$route.query && (this.$route.query.rightsId as string) || "";
    }
    
    /**
     * 绿通项目数据
     * @private
     * @returns void
     */
    private greenwayItem: any =
    {
        serverItemName: "",
        serverPrice: null
    }

    // 当前日期后3天
    private dateThree: any = new Date(dayjs().add(3, 'day').valueOf());
    
    /**
     * 医院服务id
     * @private
     * @returns string
     */
    private get hospitalServerId(): string
    {
        return this.$route.query && (this.$route.query.id as string) || "";
    }
    
    /**
     * 当前位置
     * @private
     * @returns any
     */
    private get location(): any
    {
        return this.$store.getters.location || {};
    }

    /**
     * 显示诊断结果
     * @private
     * @returns void
     */
    protected onFruitPop(): void
    {
        if (!this.popFruitValue)
        {
            this.popFruitValue = this.formModel.diagnosisResult;
        }
        this.showFruit = true;
    }

    /**
     * 诊断结果弹出层-确定按钮
     * @private
     * @returns void
     */
    protected onPopFruitBtn(): void
    {
        this.formModel.diagnosisResult = this.popFruitValue;
        this.showFruit = false;
    }

    /**
     * 医院类型
     * @protected
     */
    // protected onHospitalType(value): string
    // {
    //     return EnumUtils.getEntry(value, HospitalType)?.alias || "";
    // }

    /**
     * 跳转订单明细
     * @private
     * @returns void
     */
    private onActionOrderDetail(id: string): void
    {
        this.$router.push({name: "otherOrder-make-detail", query:{id: id, channelType: "1"}});
    }

    /**
     * 组件进来之前
     * @private
     * @returns void
     */
    private beforeRouteEnter(to, from, next): void
    {
        let appid = localStorage.getItem("appid");
        if (!tools.isAndroid() && `/${appid}${to.path}` !== location.pathname)
        {
           location.assign(`/${appid}${to.fullPath}`);
        }
        else
        {
            next();
        }
    }

    /**
     * 预约按钮
     * @private
     * @returns Promise<void>
     */
    private async onMake(): Promise<void>
    {
        this.$router.push({name:"home"})
        // try
        // {
        //     let data = Object.assign({},this.formModel);
        //     if (this.rightsId)
        //     {
        //         data.rightsId = this.rightsId;
        //     }
        //     let {content: res} = await GreenwayFormService.instance.greenwayForm(data);
        //     let orderId = null;
        //     if (res.data)
        //     {
        //         orderId = res.data.orderId
        //     }
        //     // 需要支付
        //     if (res.data.orderStatus == 1)
        //     {
        //         this.onBridgeReady(res.data, res.data.orderId);
        //     }
        //     else // 不需要支付
        //     {
        //         Toast.success("预约成功！");
        //         this.$router.push({name: "otherOrder-makeSuccss", query:{id: orderId, channelType: "1", actionName: "channel-make-detail"}});
        //     }
            
        // }
        // catch (err)
        // {
        //     Toast(err)
        // }
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    protected get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 生命钩子函数
     * @private
     * @returns void
     */
    private created():void
    {
        this.selectUser =
        {
            id: this.userInfo.userId,
            memberType: 1,
            familyType: 1,
            memberName: this.userInfo.userName
        };
        this.formModel.memberId = this.userInfo.userId;
    }

    /**
     * 付款
     * @private
     * @returns void
     */
    private onBridgeReady(model: any, orderId: string): void
    {
        let data =  model.payRes && model.payRes.parameter && JSON.parse(model.payRes.parameter)
        let self = this;
        WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            {
                appId: data.appId, // 公众号名称，由商户传入
                timeStamp: data.timeStamp, // 时间戳，自1970年以来的秒数
                nonceStr: data.nonceStr, // 随机串
                package: data.package, //
                signType: data.signType, // 微信签名方式:
                paySign: data.paySign // 微信签名
            },
            function(res)
            {
                if (res.err_msg === "get_brand_wcpay_request:ok")
                {
                    self.$router.push({name: "otherOrder-makeSuccss", query:{id: orderId, channelType: "1", actionName: "channel-make-detail"}});
                    Toast.success("支付成功");
                    // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                }
                else if(res.err_msg === "get_brand_wcpay_request:cancel")
                {
                    // 取消支付
                    self.onActionOrderDetail(orderId);
                }
                else
                {
                    self.onActionOrderDetail(orderId);
                    Toast.fail("支付失败");
                }
            }
        );
    }

    /**
     * 确认切换用户
     * @private
     * @returns void
     */
    protected onConfirmUser(data: any): void
    {
        if(data.id !== this.selectUser.id)
        {
            this.formModel.memberId = data.id;
            this.selectUser = Object.assign({},data);
        }
    }

    /**
     * 预约时间确认
     * @private
     * @returns void
     */
    private onConfirmDate(date) {
        this.formModel.reserveTime = dayjs(date).format("YYYY-MM-DD");
        this.showDate = false;
    }

    /**
     * 确诊时间确认
     * @private
     * @returns void
     */
    private onConfirmDiagnosisTime(date) {
        this.formModel.diagnosisTime = dayjs(date).format("YYYY-MM-DD");
        this.showDiagnosisTime = false;
    }

    /**
     * 是否有重大疾病-确定
     * @private
     * @returns void
     */
    private onConfirmDisease(value) {
        this.formModel.isDisease = value;
        this.showDisease = false;
    }

    /**
     * 是否有重大疾病-取消
     * @private
     * @returns void
     */
    private onCancelDisease() {
        this.showDisease = false;
    }
    
    /**
     * 是否持有该医院的住院通知单-确定
     * @private
     * @returns void
     */
    private onConfirmNotify(value) {
        this.formModel.isHospitalNotice = value;
        this.showNotify = false;
    }

    /**
     * 是否持有该医院的住院通知单-取消
     * @private
     * @returns void
     */
    private onCancelNotify() {
        this.showNotify = false;
    }

    /**
     * 上传文件
     * @protected
     * @reyurns Array<any>
     */
    protected uploader: Array<any> = []

    /**
     * 上传文件
     * @private
     * @returns void
     */
    private onUploadFile(): void
    {
        // 重大疾病判断
        let isGreat: Array<any> = [{model: "reserveTime", title: "请先选择期望就诊时间！"},
                                {model: "hospitalName", title: "请先输入期望就诊医院！"},
                                {model: "department", title: "请先输入期望就诊科室！"},
                                {model: "linkPhone", title: "请先输入联系电话！"},
                                {model: "diagnosisTime", title: "请先选择确诊时间！"},
                                {model: "diagnosisHospital", title: "请先输入确诊医院！"},
                                {model: "diagnosisDepartment", title: "请先输入确诊科室！"},
                                {model: "diagnosisResult", title: "请先输入诊断结果！"}];
        // 非重大疾病判断
        let isNotGreat: Array<any> = [{model: "reserveTime", title: "请先选择期望就诊时间！"},
                                {model: "hospitalName", title: "请先输入期望就诊医院！"},
                                {model: "department", title: "请先输入期望就诊科室！"},
                                {model: "linkPhone", title: "请先输入联系电话！"},
                                {model: "remark", title: "请先输入病情/需求！"}];
        let pd = true;
        if (this.formModel.isDisease == '是') // 重大疾病
        {
            isGreat.forEach(item => {
                if (!this.formModel[item.model] && pd)
                {
                    Toast(item.title);
                    pd = false;
                }
            })
            if (!pd)
            {
                return;
            }
            if(this.uploader.length <= 0)
            {
                Toast("请先上传诊断图片！");
                return;
            }
        }
        else // 非重大疾病
        {
            isNotGreat.forEach(item => {
                if (!this.formModel[item.model] && pd)
                {
                    Toast(item.title);
                    pd = false;
                }
            })
            if (!pd)
            {
                return;
            }
        }
        Dialog.confirm({
            title: '温馨提示',
            message: '确认成功后将消耗一次权益可用次数，取消订单不会返还权益，是否继续？',
        })
        .then(async (): Promise<void> => {
            this.formModel.isHospitalNotice = this.formModel.isHospitalNotice == "是" ? true : false;
            this.formModel.isDisease = this.formModel.isDisease == "是" ? true : false;
            if (this.uploader.length > 0)
            {
                try
                {
                    let formData = new FormData();
                    this.uploader.forEach(item=>
                    {
                        formData.append("files", item.file);
                    })
                    formData.append("useType", "1");
                    let {content: result} = await FileService.instance.uploadFile(formData);
                    if (result.data)
                    {
                        let ids = "";
                        result.data.forEach(item => {
                            ids += item.id + ','
                        });
                        this.formModel.diagnosisPicture = ids.substring(0, ids.length - 1);
                        this.onMake();
                    }
                }
                catch(err)
                {
                    Toast(JSON.stringify(err));
                }
            }
            else
            {
                this.onMake();
            }
        })
        .catch(() => {
            // on cancel
        });
    }

    /**
     * 显示备注框弹出层
     * @private
     * @returns void
     */
    protected onShowRemarkPop(): void
    {
        if (!this.popRemarkValue)
        {
            this.popRemarkValue = this.formModel.remark;
        }
        this.showRemark = true;
    }

    /**
     * 显示备注框弹出层-确定按钮
     * @private
     * @returns void
     */
    protected onPopRemarkBtn(): void
    {
        this.formModel.remark = this.popRemarkValue;
        this.showRemark = false;
    }

    /**
     * 获取与本人关系
     * @private
     * @returns string
     */
    protected getRelationship(value: number): string
    {
        return EnumUtils.getEntry(value, RELATION) && EnumUtils.getEntry(value, RELATION).description;
    }


}
